<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child detail-page-tile">
        <div v-if="displaySubQuoteImage">
          <h6 class="title is-6">Add Image(s) From Additional</h6>
          <div class="field">
            <span v-for="quote in subQuoteNos"
              :key="quote.key">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input :id="`quote${quote.key}`"
                  type="checkbox"
                  :value="quote"
                  v-model="selectedQuotes"
                  checked="checked">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>{{ quote.key === 0 ? 'Quote' : `Add ${quote.key}` }}</label>
                </div>
              </div>
            </span>
          </div>
        </div>
        <template v-for="category in imageCategories">
          <image-select-category :categories="imageCategories"
            :quote-id="quoteId"
            :key="category.quoteImageCategoryId"
            :images="imagesWithInfo"
            :category="category.name"
            :category-id="category.quoteImageCategoryId"
            :category-order-index="category.orderIndex"
            :quote-ids="quoteIds"
            :max-selected="maxSelected"
            :is-audanet="isAudanet"
            :sub-title="subtitle"
            :pre-select="preSelect"
            @update-images="updateImages" />
        </template>
      </article>
    </div>

  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import ImageSelectService from './ImageSelectService'
import ImageSelectCategory from './ImageSelectCategory.vue'

const MaximumImages = 99

export default {
  name: 'ImageSelect',
  components: {
    ImageSelectCategory
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    quoteIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxSelected: {
      type: Number,
      default: MaximumImages
    },
    subtitle: {
      type: String,
      default: 'Select images to attach'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    subQuoteNos: {
      type: Array,
      default: function () {
        return []
      }
    },
    showPrivate: {
      type: Boolean,
      default: false
    },
    preSelect: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      size: 200,
      imagesWithInfo: [],
      imageIds: null,
      isLoadingImages: false,
      selectedImages: [],
      selectedQuotes: [],
      allImages: [],
      imageCategories: null
    }
  },
  computed: {
    hasSelected() {
      return this.imagesWithInfo.some((i) => i.isSelected)
    },
    selectedCount() {
      return this.imagesWithInfo.reduce(function (total, item) {
        return item.isSelected ? total + 1 : total
      }, 0)
    },
    subtitleText() {
      if (this.maxSelected < MaximumImages) {
        if (this.subtitle.includes('<max>')) {
          return `${this.subtitle.replace('<max>', `${this.maxSelected}`)}`
        } else {
          return `${this.subtitle} (${this.maxSelected} images)`
        }
      } else {
        return this.subtitle
      }
    },
    selected() {
      if (this.imagesWithInfo) {
        return this.imagesWithInfo.map(function (i) {
          return i.isSelected
        })
      }
    },
    displaySubQuoteImage() {
      return this.subQuoteNos.length > 1 ? true : false
    }
  },
  watch: {
    selected(newVal, oldVal) {
      if (this.imagesWithInfo) {
        const selectedImages = this.imagesWithInfo
          .filter((i) => i.isSelected)
          .map(function (i) {
            return {
              quoteImageId: i.quoteImageId,
              fileName: i.fileName
            }
          })
        this.$emit('input', selectedImages)
      }
    },
    isLoadingImages(newVal, oldVal) {
      this.$emit('update:loading-images', newVal)
    },
    selectedQuotes: function (newVal, oldVal) {
      if (this.displaySubQuoteImage) this.getSelectedQuoteImages()
    }
  },
  created() {
    this.selectedImages = this.value
    this.getThumbnails()
    this.selectedQuotes = this.subQuoteNos
    this.getImageCategories()
  },
  methods: {
    // async getThumbnail(id, index) {
    //   var thumbnail = await ImageSelectService.getImageThumbnail(id, this.size)
    //   this.images.splice(index, 1, thumbnail)
    // },
    async getImageCategories() {
      this.imageCategories = await ImageSelectService.getQuoteImageCategories()
      if (!this.showPrivate) this.imageCategories = this.imageCategories.filter((o) => !o.isPrivate)
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await ImageSelectService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      const vm = this
      if (this.quoteIds.length === 0) {
        this.imageIds = await ImageSelectService.getImageIds(this.quoteId)
      } else {
        this.imageIds = []
        const idPromises = this.quoteIds.map(function (id) {
          return ImageSelectService.getImageIds(id)
        })
        const results = await Promise.all(idPromises)
        this.imageIds = [].concat.apply([], results)
      }
      const promises = this.imageIds.map(function (id, index) {
        // vm.getThumbnail(id, index)
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)

      this.allImages = this.imagesWithInfo

      this.imagesWithInfo.forEach(function (i) {
        if (vm.selectedImages.some((s) => s === i.quoteImageId)) {
          i.isSelected = true
        }
        if (vm.isAudanet) {
          i.isSelected = i.isSelected || i.referenceId === ''
        }
      })
      this.isLoadingImages = false
      if (this.preSelect === 'NotSent') {
        this.imagesWithInfo.forEach(item => {
          if (item.statusId === 1) {
            item.isSelected = true
          }
        })
      }
    },
    setSelectedStatusAll(selected) {
      this.imagesWithInfo.forEach((i) => (i.isSelected = selected))
    },
    getSelectedQuoteImages() {
      if (this.allImages.length !== 0) {
        const selectedQuoteImages = []
        for (var key in this.selectedQuotes) {
          for (let i = 0; i < this.allImages.length; i++) {
            const currentImage = this.allImages[i]
            if (currentImage.quoteId === this.selectedQuotes[key].value) selectedQuoteImages.push(this.allImages[i])
          }
        }
        this.imagesWithInfo = selectedQuoteImages
        this.setSelectedStatusAll(true)
      }
    },
    updateImages(images) {
      this.imagesWithInfo = _cloneDeep(images)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header-title {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem !important;
}
</style>
